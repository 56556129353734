import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ScriptService } from 'ngx-script-loader';
import { Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Address } from '../models/venue';
import { MapsService } from '../services/maps.service';

declare const google: any;

@Component({
  selector: 'app-customer-address-editor',
  templateUrl: './customer-address-editor.component.html',
  styleUrls: ['./customer-address-editor.component.scss']
})
export class CustomerAddressEditorComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  address: Address;

  @Input()
  isRequired: true;

  @Input()
  hint?: string = this.translate.instant("We need your address for the invoice we will send you");

  @Input()
  toucher: Observable<any>;

  @Output()
  onCustomerAddressChange: EventEmitter<Address> = new EventEmitter();

  @Output()
  onValidityChange: EventEmitter<boolean> = new EventEmitter();

  customerAddress: FormGroup = new FormGroup({
    formattedAddress: new FormControl(''),
    street: new FormControl(),
    number: new FormControl(),
    postal: new FormControl(),
    municipality: new FormControl(),
    region: new FormControl(),
    country: new FormControl(),
  })

  //Address properties
  addresses: any[];
  autoCompleteService: any;
  isLoadingAddresses: boolean;
  addressObj: any;

  sub: Subscription;

  constructor(
    private translate: TranslateService,
    private scriptService: ScriptService,
    private mapsService: MapsService,) { }

  ngOnInit(): void {
    //Setup google maps API for usage
    this.scriptService.loadScript('https://maps.googleapis.com/maps/api/js?key=' + environment.firebase.apiKey + "&language=no&libraries=places")
    .subscribe(() => {
      const interval = setInterval(() => {
        if (typeof google !== 'undefined' && google?.maps?.places) {
          clearInterval(interval);
          this.onGoogleMapsSDKReady();
        }
      }, 100); // Check every 100ms
    });
    if (this.address) {
      this.customerAddress.setValue(this.address);
      this.onCustomerAddressChange.emit(this.address);
      this.onValidityChange.emit(this.customerAddress.valid);
    }
    this.customerAddress.valueChanges.subscribe((newAddress) => {
      this.onCustomerAddressChange.emit(newAddress);
      this.onValidityChange.emit(this.customerAddress.valid);
    });
    this.sub = this.toucher?.subscribe(() => this.customerAddress.markAllAsTouched());
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.isRequired?.currentValue) {
      const isRequired = changes?.isRequired?.currentValue;
      if (isRequired) {
        this.customerAddress.get('formattedAddress').setValidators(Validators.required);
      } else {
        this.customerAddress.get('formattedAddress').clearValidators();
      }
    }
    if (changes?.address?.currentValue) {
      this.customerAddress.setValue(changes.address.currentValue);
    }
    this.customerAddress.updateValueAndValidity();
  }


  //Address setup
  onGoogleMapsSDKReady() {
    if (google?.maps?.places?.AutocompleteService) {
      this.autoCompleteService = new google.maps.places.AutocompleteService();
      this.customerAddress.get(["formattedAddress"]).valueChanges
        .subscribe(newAddress => this.loadAddressSuggestions(newAddress));
    }
  }

  loadAddressSuggestions(newAddress: string): void {
    if (newAddress) {
      this.isLoadingAddresses = true;
      this.autoCompleteService.getPlacePredictions(
        {
          input: newAddress,
          componentRestrictions: {
            country: environment.ui.showOnForm.venueCountry
          },
          location: new google.maps.LatLng(
            environment.town.lat,
            environment.town.lng),
          radius: 10000,
          types: ['premise', 'street_number', 'post_box']
        }, (predictions, status) => this.displaySuggestions(predictions, status));
    }
  }

  displaySuggestions(predictions, status) {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      this.addresses = predictions;
    } else {
      this.addresses = [];
    }
    this.isLoadingAddresses = false;
  }

  onAddressSelected(event) {
    if (event.option.value) {
      this.mapsService.getLocation(event.option.value).pipe(first()).subscribe(
        (response) => {
          if (response && response.status == 'OK' && response.results
            && response.results.length > 0) {
            const addressObj = this.getAddressObj(response.results[0].address_components);
            this.customerAddress.setValue({ formattedAddress: response.results[0].formatted_address, ...addressObj });
          } else {
            this.customerAddress.setValue(null);
          }
        }
      );
    }
  }

  getAddressObj(addressComponents: any[]): any {
    return {
      street: this.getAddressComponent(addressComponents, 'route'),
      number: this.getAddressComponent(addressComponents, 'street_number'),
      postal: this.getAddressComponent(addressComponents, 'postal_code'),
      municipality: this.getAddressComponent(addressComponents, 'administrative_area_level_2'),
      region: this.getAddressComponent(addressComponents, 'administrative_area_level_1'),
      country: this.getAddressComponent(addressComponents, 'country'),
    }
  }

  getAddressComponent(components: any, name: string) {
    const filtered: any[] = components?.filter((comp: any) => comp.types?.indexOf(name) != -1) || [];
    if (filtered.length > 0) {
      return filtered[0].long_name || '';
    }
    return '';
  }

}
