<app-my-json-ld [itemLD]="productLD"></app-my-json-ld>
<div fxLayout="column" [id]="event.event_slug" fxLayoutAlign="start center" class="event-list-item">
  <div fxLayout="row" class="full-width">
    <!-- Event info -->
    <div fxFlex="730px" fxFlex.xs="grow" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="20px" fxLayoutGap.xs="0px">
      <!-- Date for the desktop version -->
      <div fxFlex="110px" class="dates-block" fxHide.xs="true" fxLayout="column" fxLayoutAlign="space-around center">
        <div *ngIf="event.type != 'super-event'">
          <div class="month third-color">{{getMomentStartDate().format('MMM')}}</div>
          <div class="day">{{getMomentStartDate().format('D')}}</div>
          <div class="week-day">{{getMomentStartDate().format('ddd')}}</div>
        </div>
        <div *ngIf="event.type == 'super-event'">
          <div class="month third-color festival">{{getMomentStartDate().format('MMM')}} {{getMomentEndDate().format('MMM') == getMomentStartDate().format('MMM') ? '' : '- ' + getMomentEndDate().format('MMM')}}</div>
          <div class="day festival">{{getMomentStartDate().format('D')}} {{getMomentEndDate().format('D') == getMomentStartDate().format('D') ? '' : '- ' + getMomentEndDate().format('D')}}</div>
          <div class="week-day festival">{{getMomentStartDate().format('ddd')}} {{getMomentEndDate().format('ddd') == getMomentStartDate().format('ddd') ? '' : '- ' + getMomentEndDate().format('ddd')}}</div>
        </div>
      </div>
      <!-- Title, date, time, venue, category, price, age restriction, and more -->
      <div [fxFlex]="hasImages || event.eventCancelled || event.eventSoldOut ? '350px' : '620px'" fxFlexOrder="2" fxFlexOrder.xs="2" fxFlex.xs="noshrink" fxFlexAlign="start" fxLayout="column">
        <div fxFlex="auto" fxFlex.xs="nogrow" class="mat-title title">
          <a [routerLink]="['/event', event.event_slug]">{{utilsService.getTitle(event)}}</a>
        </div>
        <div fxFlex="10px" fxShow.xs fxHide class="mat-subheading-1 time" fxLayout="row" fxLayoutAlign="start center">
          <img class="event-info-icon" src="assets/icons/schedule.png" />
          <span *ngIf="event.type != 'super-event'" fxFlex="nogrow"><span class="item-info">{{displayStartDate()}} - {{displayTime()}}</span></span>
          <span *ngIf="event.type == 'super-event'" fxFlex="nogrow"><span class="item-info">{{displayStartDate()}} - {{displayEndDate()}}</span></span>
        </div>
        <div fxFlex="10px" fxShow fxHide.xs *ngIf="event.type != 'super-event' && event.startTime" class="mat-subheading-1 time" fxLayout="row" fxLayoutAlign="start center">
          <img class="event-info-icon" src="assets/icons/schedule.png" />
          <span fxFlex="nogrow"><span class="item-info">{{displayTime()}}</span> <span *ngIf="event.registrationEnabled"><span class="item-info soldout" *ngIf="event.repetitions?.length > 0 && event.eventSoldOut">{{'Sold out' | translate}}</span><span class="item-info available" *ngIf="!event.eventSoldOut && !event.eventCancelled && event.repetitions?.length > 0">{{event.availableTickets - event.activeTickets}} {{'tickets available' | translate}}</span></span><span class="item-info cancelled" *ngIf="event.registrationEnabled && event.repetitions?.length > 0 && event.eventCancelled">{{'cancelled_event' | translate}}</span></span>
        </div>
        <div *ngIf="event.mode == 'online'" fxFlex="10px" class="mat-subheading-1 place-container" fxLayout="row" fxLayoutAlign="start center">
          <img class="event-info-icon venue-icon" src="assets/icons/world.svg" />
          <a [routerLink]="['/mode', 'online']">{{'Online' | translate}}</a>
        </div>
        <div *ngIf="event.venue" fxFlex="10px" class="mat-subheading-1 place-container" fxLayout="row" fxLayoutAlign="start center">
          <img class="event-info-icon venue-icon" src="assets/icons/location.png" />
          <a [routerLink]="['/venue', event.venue.slug]">{{event.venue.name}}</a>
        </div>
        <div fxFlex="auto" class="mat-subheading-1 category-container" fxLayout="row" fxLayoutAlign="start center">
          <img class="event-info-icon" src="assets/icons/category.png" />
          <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
            <a fxFlex="auto" *ngFor="let categoryID of event.categories" [routerLink]="['/category', categoryID]">
              {{categoryService.getCategoryName(categoryID)}}
            </a>
          </div>
        </div>
        <div fxFlex="10px" class="mat-subheading-1 info" fxLayout="row" *ngIf="event.ticketsInformation != 'noTicketsInfo'" fxLayoutAlign="start center">
          <img class="event-info-icon" src="assets/icons/info.svg" />
          <span class="item-info" *ngIf="getMaxPrice() == 0" fxFlex="nogrow">{{'Free' | translate}}</span>
          <span class="item-info" *ngIf="getMaxPrice() > 0" fxFlex="nogrow"><span *ngIf="translate.currentLang != 'nb'">{{environment.ui.labelPrice.en}}</span><span
              *ngIf="translate.currentLang == 'nb'">{{environment.ui.labelPrice.nb}}</span>: {{getMaxPrice()}},- <span *ngIf="getMaxPrice() != getMinPrice() "> /
              {{getMinPrice()}},-</span></span>
          <span class="item-info" *ngIf="(event.ticketsURL || event.registrationEnabled)" fxFlex="nogrow" class="tickets-link-container">| <a *ngIf="!event.registrationEnabled && getMaxPrice() > 0 && event.ticketsURL" [href]="event.ticketsURL" target="_blank">{{'Buy tickets' | translate}} <img src="assets/icons/open_in_new.svg" /></a>
            <a *ngIf="!event.registrationEnabled && getMaxPrice() == 0 && event.ticketsURL" [href]="event.ticketsURL" target="_blank">{{'Register' | translate}} <img src="assets/icons/open_in_new.svg" /></a>
            <a *ngIf="event.registrationEnabled" [routerLink]="['event', event.event_slug]" fragment="book-tickets">{{'Book tickets' | translate}}</a>
          </span>
        </div>
        <div fxFlex="10px" *ngIf="event.ageRestriction != 'no-restriction'" class="mat-subheading-1 info" fxLayout="row" fxLayoutAlign="start center">
          <img class="event-info-icon" src="assets/icons/person.png" />
          <span class="item-info" *ngIf="event.ageRestriction == 'minimum-age' && event.minimumAge" fxFlex="nogrow">{{'From' | translate}} {{event.minimumAge}} <span translate>years</span></span>
          <span class="item-info" *ngIf="event.ageRestriction == 'maximum-age' && event.maximumAge" fxFlex="nogrow">{{'Until' | translate}} {{event.maximumAge}} <span translate>years</span></span>
        </div>
      </div>
      <!-- Images, banner sold out or cancelled -->
      <div fxFlex="250px" fxFlex.sm="230px" *ngIf="hasImages || event.eventCancelled || event.eventSoldOut" fxFlexOrder="3" fxFlexOrder.xs="1" fxFlex.xs="100%" class="text-center block-picture">
        <div class="banner-container" *ngIf="!(event.images?.length > 0) && (event.eventCancelled || event.eventSoldOut)">
          <div class="banner soldout" *ngIf="event.eventSoldOut">{{'Sold out' | translate}}</div>
          <div class="banner cancelled" *ngIf="event.eventCancelled">{{'cancelled_event' | translate}}</div>
        </div>
        <div class="ribbon-container" *ngIf="hasImages && (event.eventCancelled || event.eventSoldOut) && (!event.registrationEnabled || event.repetitions?.length == 0)">
          <div class="ribbon soldout" *ngIf="event.eventSoldOut">{{'Sold out' | translate}}</div>
          <div class="ribbon cancelled" *ngIf="event.eventCancelled">{{'cancelled_event' | translate}}</div>
        </div>
        <div *ngIf="hasImages">
          <div class="image-blur" [style.background-image]="'url('+event.images[0].urlSmall+')'"></div>
          <a [routerLink]="['/event', event.event_slug]" >
            <div *ngIf="event.images?.length > 0 && event.images[0].urlSmall" class="image" [style.background-image]="'url('+event.images[0].urlSmall+')'"></div>
            <img class="image-mobile" fxHide.gt-xs fxShow.lt-md [src]="event.images?.length > 0 && event.images[0].urlSmall"/>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Repetitions -->
  <div fxLayout="row" fxLayoutAlign="start start" class="full-width">
    <div fxFlexOffset="130px" fxFlexOffset.xs="0" fxFlex="620px" fxFlex.xs="grow" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="20px">
      <div fxLayout="row" fxLayoutAlign="center center" fxFlexOrder="3" *ngIf="nextRepetitions.length > 0" class="rep-container full-width">
        <div fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="10px">
          <div *ngFor="let repetition of event.repetitions.slice(0, 3)" fxFlex="176px" fxFlex.xs="auto" class="repetition" fxFlexAlign="stretch" fxLayout="column"
            fxLayoutAlign="start start" fxLayoutGap="5px">
            <div >{{getStartDate(repetition)}}</div>
            <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
              <div class="subtitle" *ngIf="repetition.mode == 'offline' && repetition.venue?.name" fxLayout="row"
                fxLayoutAlign="start center">{{repetition.venue.name}}</div>
              <div class="subtitle" *ngIf="repetition.mode == 'online'" fxLayout="row" fxLayoutAlign="start center">
                {{'Online' | translate}}</div>
              <div class="event-warning cancelled" *ngIf="repetition.eventCancelled" translate>cancelled_event</div>
              <div class="event-warning soldout" *ngIf="repetition.eventSoldOut" translate>Sold out</div>
            </div>
            <div class="event-warning info-num-tickets" *ngIf="!repetition.eventSoldOut && !repetition.eventCancelled && event.registrationEnabled">
              <a *ngIf="event.registrationEnabled" [routerLink]="['event', event.event_slug]" fragment="book-tickets">{{repetition.availableTickets - repetition.activeTickets}} {{'tickets available' | translate}}</a>
              </div>
          </div>
          <a class="repetition" fxFlexAlign="stretch" fxLayout="row" fxFlex.xs="80px" fxLayoutAlign="center center" fxFlex="60px" *ngIf="nextRepetitions.length - 4 > 0"
            [routerLink]="['/event', event.event_slug]">+{{nextRepetitions.length - 3}}</a>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" fxFlexOrder="4" *ngIf="(isLoading$ | async) || childrenEvents.length > 0" class="festival-events">
        <div fxFlex="grow" *ngIf="isLoading$ | async">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="10px">
          <a *ngFor="let childrenEvent of childrenEvents.slice(0, 3);" [routerLink]="['/event', childrenEvent.event_slug]" fxFlex="176px" fxFlex.xs="auto" class="repetition" fxFlexAlign="stretch" fxLayout="column"
          fxLayoutAlign="start start" fxLayoutGap="5px">
            <div>{{this.utilsService.getTitle(childrenEvent)}}</div>
            <div class="subtitle">{{utilsService.getStartDate(childrenEvent)}}</div>
          </a>
          <a class="repetition all-link" fxFlexAlign="stretch" fxLayout="row" fxLayoutAlign="center center" fxFlex="60px" *ngIf="totalChildrenEvents - 3 > 0" [routerLink]="['/event', event.event_slug]">
            <span>+{{totalChildrenEvents - 3}}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<mat-divider *ngIf="!last"></mat-divider>
